import React from "react"
import { graphql, PageProps, Link as GatsbyLink } from "gatsby"
import styled from "@emotion/styled"
import { Layout } from "../components/Layout"

const Title = styled.h1`
  font-family: PirataOne;
  font-size: 3rem;
`

const PostContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
`

const Post = styled.div`
  margin: 1rem;
  flex: 1;
  min-width: 256px;
  max-width: 512px;
`

const PostTitle = styled.h2`
  font-family: PirataOne;
  font-size: 1rem;
`

interface QueryProps {
  item: {
    name: string
    slug: string
    posts?: {
      slug: string
      image: {
        title: string
        file: {
          name: string
        }
      }
    }[]
    category: {
      name: string
      slug: string
    }[]
  }
}

const SubcategoryTemplate: React.FC<PageProps<QueryProps>> = ({ data }) => {
  if (!data.item) return null

  return (
    <Layout
      title={data.item.name}
      breadcrumbs={[
        { name: data.item.category[0].name, slug: data.item.category[0].slug },
        { name: data.item.name },
      ]}
    >
      <Title>{data.item.name}</Title>
      <PostContainer>
        {data.item.posts?.map(item => (
          <Post key={item.slug}>
            <PostTitle>{item.image.title}</PostTitle>
            {item.image.file.name && (
              <GatsbyLink to={`/${item.slug}/`} aria-label={item.image.title}>
                <picture>
                  <source srcSet={`/images/${item.image.file.name.replace(".jpeg", ".webp")}`} />
                  <img src={`/images/${item.image.file.name}`} alt={item.image.title} />
                </picture>
              </GatsbyLink>
            )}
          </Post>
        ))}
      </PostContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    item: contentfulSubcategory(id: { eq: $id }) {
      name
      slug
      posts: image_post {
        slug
        image {
          title
          file {
            name: fileName
          }
        }
      }
      category {
        name
        slug
      }
    }
  }
`

export default SubcategoryTemplate
